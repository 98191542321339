import {lazy} from 'react'
import {deburr, isNumber, isString, kebabCase, split, keyBy, map, mapValues} from 'lodash-es'
import {css} from 'styled-components'
import theme from './theme/theme'
import {PhoneIcon, MailIcon, WebIcon} from './assets/icons'


const chooseBreakpoint = (breakpoint) => {
  return isNumber(breakpoint) ? breakpoint : theme.global.media[breakpoint]
}

export const media = ({
  down: (breakpoint) => (styles) => css`
    @media screen and (max-width: ${chooseBreakpoint(breakpoint)}px) {
      ${styles}
    }
  `,
  up: (breakpoint) => (styles) => css`
    @media screen and (min-width: ${chooseBreakpoint(breakpoint)}px) {
      ${styles}
    }
  `,
  between: (startPoint, endPoint) => (styles) => css`
    @media screen
      and (min-width: ${chooseBreakpoint(startPoint)}px)
      and (max-width: ${chooseBreakpoint(endPoint)}px) {
      ${styles}
    }
  `,
})

export const isExternal = (to) => {
  if (typeof window !== 'undefined' && to) {
    const currentHost = window.location.host
    const linkHostMatch = to.match(/:\/\/(.[^/]+)/)

    if (!linkHostMatch) return false
    const [, linkHost] = linkHostMatch

    return currentHost !== linkHost
  }

  return true
}

export const normalizeId = (text) => kebabCase(deburr(text))

const waitForLoad = () => (new Promise((resolve) => {
  if (typeof window === 'undefined') return

  const handlePageLoad = (e) => {
    if (e) window.removeEventListener('load', handlePageLoad)
    if ('requestIdleCallback' in window) {
      const callback = (deadline) => {
        if (!deadline.timeRemaining() > 1000) return window.requestIdleCallback(callback)
        resolve()
      }
      window.requestIdleCallback(callback)
    } else {
      resolve()
    }
  }

  if (document.readyState === 'complete') {
    handlePageLoad()
  } else {
    window.addEventListener('load', handlePageLoad)
  }
}))

export const lazyAfterLoad = (component) => lazy(async () => {
  await waitForLoad()
  return component()
})

export const contactMapText = {
  phone: 'tel: ',
  mail: 'mailto: ',
  web: 'https://',
}

export const contactMapIcon = {
  phone: <PhoneIcon />,
  mail: <MailIcon />,
  web: <WebIcon />,
}

export const getVideoID = (url) => {
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
  const match = url.match(regExp)
  return (match && match[7].length === 11) ? match[7] : false
}

export const checkUrl = (url) => {
  const urlRemove = new RegExp(process.env.GATSBY_URL_REMOVE, 'g') // eslint-disable-line no-process-env
  return isString(url) &&
    url.includes(process.env.GATSBY_SITE_URL) // eslint-disable-line no-process-env
    ? url.replace(urlRemove, '')
    : url
}

export const getLocale = (locale) => split(locale, '_')[0]

export const getLangToSlug = ({translations, lang, uri}) => {
  const translationsByLocale = keyBy(
    map(translations, ({locale, href}) => ({lang: getLocale(locale), href})),
    'lang'
  )

  const currentSlug = {
    [lang]: uri,
  }

  return {
    ...currentSlug,
    ...mapValues(translationsByLocale, 'href'),
  }
}
