import {graphql, useStaticQuery} from 'gatsby'
import {Helmet} from 'react-helmet'


const GA = () => {
  const data = useStaticQuery(graphql`
    query Options {
      wp {
        options {
          options {
            gaKey
          }
        }
      }
    }
  `)

  if (!data.wp.options.options?.gaKey) return null

  return (
    <Helmet>
      <script>
        {`window.ga=window.ga||function(){(ga.q=ga.q||[]).push(arguments)};ga.l=+new Date;
        ga('create', '${data.wp.options.options.gaKey}', 'auto');
        ga('send', 'pageview');`}
      </script>
      <script async src="https://www.google-analytics.com/analytics.js" />
    </Helmet>
  )
}


export default GA
