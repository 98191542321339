module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://admin.mrava.sk/graphql","schema":{"requestConcurrency":10,"previewRequestConcurrency":5},"production":{"allow404Images":true,"allow401Images":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Mrava","short_name":"Mrava","start_url":"/","background_color":"#1A1A1A","theme_color":"#CEAC6F","display":"minimal-ui","icon":"src/assets/general/favicon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"6943fecd68d1afd4dbb30ae498d4fb4b"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
