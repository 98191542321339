export const getCookie = (name) => {
  if (typeof document !== 'undefined') {
    return document.cookie.split('; ').reduce((r, v) => {
      const parts = v.split('=')
      return parts[0] === name ? decodeURIComponent(parts[1]) : r
    }, '')
  }
}

export const setCookie = (name, value, days = 7, path = '/') => {
  const expires = new Date(Date.now() + days * 864e5).toUTCString()
  if (typeof document !== 'undefined') {
    document.cookie = `${name}=${encodeURIComponent(value)}; expires=${expires}; path=${path}`
  }
}

export const deleteCookie = (name, path) => {
  setCookie(name, '', -1, path)
}

export const deleteAllCookies = () => {
  deleteCookie('hasConsented', '/')
  deleteCookie('_fbp', '/')
  deleteCookie('_ga', '/')
  deleteCookie('_gid', '/')
  deleteCookie('_gat', '/')
}
