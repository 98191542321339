const fontSizeBase = 14
const fontSizeMobile = 12

const px2rem = (size, base = fontSizeBase) => {
  return `${size / base}rem`
}

export default {
  global: {
    fontSizeBase,
    fontSizeMobile,
    minimalViewport: 359,
    maximalViewport: 1200,
    duration: '200ms',
    media: {
      mobile: 768,
      tablet: 992,
      desktop: 1200,
    },
    containerMaxWidths: {
      desktop: '100rem',
      tablet: '100rem',
      mobile: px2rem(720, fontSizeMobile),
    },
  },
  colors: {
    primary: '#CEAC6F',
    secondary: '#1A1A1A',
    text: '#1A1A1A',
    textInverted: '#FFFFFF',
    background: '#FFFFFF',
    gray: '#707070',
  },
  components: {
    typography: {
      fonts: {
        heading: 'Yeseva one',
        text: 'Montserrat',
      },
      variants: {
        h1: {
          mobile: '5rem',
          desktop: '7.5rem',
        },
        h2: '2rem',
        h3: '1.125rem',
        h4: '1.125rem',
        p: '1rem',
        div: '1rem',
        a: '1.125rem',
        span: '1rem',
      },
      weight: {
        light: '300',
      },
    },
  },
}
