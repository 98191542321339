import {createGlobalStyle, css} from 'styled-components'
import reset from 'styled-reset'
import {media} from '../utils'
import texture from '../assets/general/texture.svg'
import theme from './theme'


export default createGlobalStyle`
  ${reset}

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  *,
  *:focus {
    outline: none;
  }

  ::-moz-focus-inner {
    border: 0;
    padding: 0;
  }

  :root {
    font-family: 'Montserrat', sans-serif;
    font-size: ${theme.global.fontSizeBase}px;
    scroll-behavior: smooth;

    ${media.down(theme.global.minimalViewport)(css`
      font-size: ${theme.global.fontSizeMobile / (theme.global.minimalViewport) * 100}vw;
    `)}

    ${media.between(theme.global.minimalViewport, 'mobile')(css`
      font-size: ${theme.global.fontSizeMobile}px;
    `)}

    ${media.between('mobile', 'desktop')(css`
      // font-size: ${(theme.global.fontSizeMobile / theme.global.media.tablet) * 100}vw;
    `)}

    ${media.up('mobile')(css`
      font-size: 0.9vw;
    `)}

    ${media.up(1440)(css`
      @media only screen and (max-resolution: 96dpi) {
        font-size: 12pt;
      }
    `)}
  }

  body {
    background-attachment: fixed;
    background-color: ${theme.colors.text};
    background-image: url(${texture});
    color: ${theme.colors.text};
    font-size: 1rem;
  }

  a {
    text-decoration: inherit;
  }

  button {
    font: inherit;
  }

  svg {
    width: inherit;
  }
`
