
import ThemeProvider from './src/theme/ThemeProvider'
import {GoogleFont, GA} from './src/components/custom'


export const wrapPageElement = ({element, props: {location}}) => (
    <ThemeProvider>
      {element}
    </ThemeProvider>
)

export const wrapRootElement = ({element}) => (
  <>
    <GA />
    <GoogleFont query="family=Montserrat:wght@300;400;600&display=swap" />
    <GoogleFont query="family=Yeseva+One&display=swap" />
    {element}
  </>
)
